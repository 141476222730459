<template>
    <div>
        <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col>
                <v-subheader v-if="item.heading">
                    <span class="color-font-grey">{{ item.heading }}</span>
                </v-subheader>
            </v-col>
        </v-row>

        <!-- <v-list-group
            v-else-if="item.children"
            :key="item.text"
            :value="isRouteEqualsItemPath"
            color="white"
            class="mb-1"
            :sub-group="item.subGroup"
        >
            <template v-slot:activator>
                <item-content v-if="item.href" :item="item" @click.native.stop="() => { !isRouteEqualsItemPath && $router.push(item.href) }" />
                <item-content v-else :item="item" />
            </template>
            <item v-for="(child, i) in item.children" :key="i" :item="child" />
        </v-list-group> -->

        <!-- <item-group
            v-else-if="item.children"
            :key="item.text"
            :item="item"
        /> -->
        <div v-else-if="item.children">
            <v-list-item
                :link="!!item.href"
                :exact="!!item.href"
                :to="item.href"
                class="mb-1"
            >
                <item-content :item="item" /> 
                <v-icon class="ml-2" @click.stop.prevent="groupExpanded = !groupExpanded">{{ groupExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-list-item>

            <template v-if="groupExpanded">
                <item
                    v-for="(child, i) in item.children"
                    :key="i"
                    :item="child"
                    class="mb-1"
                />
            </template>
        </div>

        <v-list-item
            v-else-if="item.handler"
            :item="item"
            class="mb-1"
            @click.prevent.stop="item.handler"
        >
            <item-content :item="item" />
        </v-list-item>

        <v-list-item
            v-else-if="item.href"
            link
            exact
            class="mb-1"
            :to="item.href"
        >
            <item-content :item="item" />
        </v-list-item>

        <v-list-item v-else>
            <item-content :item="item" />
        </v-list-item>
    </div>
</template>

<script>
import ItemContent from './ItemContent.vue'
import Item from './Item.vue'

export default {
    name: 'Item',
    components: { Item, ItemContent },
    props: {
        item: { type: Object, required: true }
    },
    data () {
        return {
            groupExpanded: false
        }
    },
    created () {
        if (this.item.children?.find((child) => child.href === this.$route.fullPath)) {
            this.groupExpanded = true
        }
    }
}
</script>