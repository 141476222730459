<template>
    <v-app-bar elevation="0" height="56px">
        <v-spacer></v-spacer>
        <span v-if="profile">{{ profile.name || profile.login }}</span>
        <v-btn icon @click.prevent.stop="dialogs.logout = true">
            <!-- <v-icon>mdi-logout-variant</v-icon> -->
             <icon name="log-out" />
        </v-btn>

        <custom-dialog
            v-model="dialogs.logout"
            max-width="500px"
            title="Вы действительно хотите выйти из учётной записи?"
        >
            <template #buttons>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    outlined
                    :color="$const.color.primary"
                    height="40px"
                    class="button-stylized"
                    @click="logout"
                >Выйти</v-btn>
            </template>
        </custom-dialog>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import Icon from '@/components/icons/Index.vue'
import CustomDialog from './CustomDialog.vue'

export default {
    name: 'HeadBar',
    components: { Icon, CustomDialog },
    computed: {
        ...mapState('user', ['profile']),
        ...mapState('license', ['items', 'loading']),
        // projectName: () => process.env.VUE_APP_PROJECT_NAME
    },
    data () {
        return {
            dialogs: {
                logout: false
            }
        }
    },
    methods: {
        async logout() {
            await this.$store.dispatch('fisoko/logout');
            await this.$store.dispatch('user/logout');
            this.$router.go('/');
        },
        // getLicensesStatusInfo(licenses) {
        //     if (!licenses) { return; }

        //     for (const license of licenses)
        //         if (this.isLicenseActive(license) && !this.isLicenseExpiresSoon(license))
        //             return 'Активировано';

        //     for (const license of licenses)
        //         if (!this.isLicenseExpired(license) && this.isLicenseExpiresSoon(license))
        //             return 'Активировано. Срок действия почти истек.';

        //     return 'Нет активированных лицензий';
        // },
        // isLicenseExpiresSoon(item) {
        //     const now = parseInt(Date.now() / 1000);
        //     const monthInSeconds = 60 * 60 * 24 * 31;
        //     return now + monthInSeconds > item.license.expires_at;
        // },
        // isLicenseExpired(item) {
        //     const now = parseInt(Date.now() / 1000);
        //     return now > item.license.expires_at;
        // },
        // isLicenseActive(item) {
        //     if (!item.license.active) { return false; }
        //     return !this.isLicenseExpired(item);
        // }
    }
}
</script>