<template>
    <v-list id="sidebar" class="d-flex flex-column h-100" dense>
        <img :src="logo" style="margin: 32px 0 36px 0;" />
        <template v-for="(item, i) in filteredByRoleItems">
            <v-divider v-if="item.border_top" :key="`divider${i}`" />
            <item :item="item" :key="i" />
        </template>
    </v-list>
</template>

<script>
import Item from './Item.vue'
import logo from '@/assets/img/icons/okovpr-logo-full-text.svg'

export default {
    name: 'Sidebar',
    components: { Item },
    data () {
        return {
            items: [
                { heading: 'Состояние', accessSections: ['license', 'app_update', ''] },
                { icon: 'home', text: 'Главная', href: '/' },
                { icon: 'lock', text: 'Восстановление доступа', href: '/update-admin-password', accessSections: ['update_admin_password'] },
                { icon: 'case', text: 'Лицензии', href: '/license', accessSections: ['license'] },
                { icon: 'download', text: 'Обновления приложения', href: '/app-update', accessSections: ['app_update'] },
                { icon: 'printer', text: 'Приложение для печати', href: '/download-print-app', accessSections: ['print_app'] },
                    // { icon: 'mdi-square-rounded-badge-outline', text: 'Обновления контента', href: '/content-update' },
                { heading: 'Отчеты', accessSections: ['fioko_data_collection']},
                { icon: 'clipboard', text: 'Отчеты ФИС ОКО', href: '/fioko-data-collection', accessSections: ['fioko_data_collection'] },
                { icon: 'file-bar-chart', text: 'Успеваемость', href: '/performance', accessSections: ['performance'] },
                { heading: 'Управление', accessSections: ['group', 'user', 'calendar']},
                { icon: 'calendar', text: 'Календарь ВПР', href: '/calendar', accessSections: ['calendar'] },
                { icon: 'users', text: 'Классы', href: '/group', accessSections: ['group'] },
                {
                    icon: 'circle-user', text: 'Пользователи', href: '/user/info', accessSections: ['user'],
                    children: [
                        { icon: 'mdi-circle-small', text: 'Администраторы ОО', href: '/user?role=admins', accessSections: ['user'] },
                        { icon: 'mdi-circle-small', text: 'Преподаватели', href: '/user?role=teacher', accessSections: ['user'] },
                        { icon: 'mdi-circle-small', text: 'Учащиеся', href: '/user?role=student', accessSections: ['user'] }
                    ]
                }
            ]
        }
    },
    computed: {
        logo () { return logo },
        filteredByRoleItems () {
            return this.items.filter(item => !item.accessSections ?
                                        true :
                                        item.accessSections.some(section => this.$store.getters['user/userCan'](section, 'view')) // User can access to one on item from 'accessSections'
                                    )
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

#sidebar {
    background-color: $application-background;
    border-right: 1px solid $color-grey;
    padding: 0 24px;

    & > div, .v-list-item, .v-list-item:hover::before {
        border-radius: $border-radius-root + 2px;
    }

    .v-list-item--active.v-list-item--link {
        color: white;
        background-color: $color-primary;
    }

    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: $color-font-default!important;
    }

}

</style>