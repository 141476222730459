<template>
    <v-app>
        <div class="d-flex h-100">
            <sidebar style="width: 300px; flex-shrink: 0;" />
            <div class="w-100">
                <head-bar />
                <main>
                    <v-container class="px-8">
                        <!-- <v-row align="start" justify="start" dark>
                            <v-col cols="12"> -->
                                <v-breadcrumbs
                                    :items="breadcrumbs"
                                    divider="‣"
                                    class="pa-0 mb-1"
                                >
                                    <template #item="{ item }">
                                        <v-breadcrumbs-item
                                            exact
                                            :disabled="item.disabled"
                                            :to="item.to"
                                            class="fs-12 fw-400 lh-14"
                                        >
                                            <span class="color-font-grey">{{ item.text }}</span>
                                        </v-breadcrumbs-item>
                                    </template>
                                </v-breadcrumbs>
                                <v-divider></v-divider>
                            <!-- </v-col>
                        </v-row> -->
                        <div class="mt-8">
                            <router-view v-if="online" :key="$route.path" />
                            <offline-warning v-else />
                        </div>
                    </v-container>
                </main>
            </div>
        </div>
        <snack-bar />
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '@/components/template/Sidebar/Index.vue'
import HeadBar from '@/components/template/HeadBar.vue'
import OfflineWarning from '@/components/template/OfflineWarning.vue'
import SnackBar from '@/components/template/SnackBar.vue'

export default {
    components: {
        Sidebar,
        HeadBar,
        OfflineWarning,
        SnackBar
    },
    computed: {
        ...mapState('app', ['online']),
    	breadcrumbs() {
    		return _.map(_.get(this.$route, 'meta.breadcrumbs', []), (item) => {
                const id = _.get(this, '$route.params.id', 0);
                _.set(item, 'to', _.replace(item.to, ':id', id));
                return item;
            });
    	}
    }
};
</script>