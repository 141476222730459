<template>
    <div class="d-flex align-center">
        <v-list-item-action v-if="item.icon" class="mr-2">
            <v-icon v-if="item.icon.startsWith('mdi-')">{{ item.icon }}</v-icon>
            <icon v-else :name="item.icon" :color="active ? 'white' : undefined" />
        </v-list-item-action>
        <div class="fs-14 fw-400 lh-16">
            {{ item.text || '...' }}
        </div>
    </div>
</template>


<script>
import Icon from '@/components/icons/Index.vue'

export default {
    components: { Icon },
    props: {
        item: { type: Object, required: true }
    },
    computed: {
        active () {
            return this.item.href === this.$route.fullPath
        }
    }
}
</script>